<template>
	<b-container fluid>
		<b-row class="justify-content-center wrapper-row" align-v="center">
			<b-col sm="auto">
				<b-card class="trial-wrapper text-center">
					<h4 slot="header">Login</h4>
					<!-- {{ openUserLoginAs }} -->
					<!-- <template v-if="showSignInAsLawyerOption">
						<div class="mx-auto" style="width: 160px">
							<b-button variant="outline-primary" @click="showNormalAuthScreen('user')" data-testid="sign-in-as-user">
								Sign in as a User
							</b-button>
						</div>
						<div class="mx-auto" style="width: 160px">
							<b-button variant="outline-primary" @click="showNormalAuthScreen('lawyer')" data-testid="sign-in-as-lawyer">
								Sign in as a Lawyer
							</b-button>
						</div>
					</template>
					<template v-else> -->
					<section id="firebaseui-auth-container" />

					<email-password-login v-if="emailLogin"/>
					<div v-else class="mx-auto" style="width: 160px">
						<b-button variant="outline-primary" @click="useEmail" data-testid="email-sign-in">
							Sign in with Email
						</b-button>
					</div>
					<div class="mx-auto clio-signIn" style="width: 160px">
					<b-button variant="outline-primary" @click="toggleClioConfirmModal()" data-testid="clio-sign-in" v-if="!isClientUserLogin">
							Sign in with Clio
						</b-button></div>
					<div class="my-2">
						<b-link @click="needsAccount" data-testid="dont-have-an-account">
							Don't have an account yet? Click here.
						</b-link>
					</div>
					<div class="my-3 forgot-password" v-if="emailLogin">
						<b-link @click="forgotPassword" data-testid="forgot-password-link">
							Forgot Password?
						</b-link>
					</div>
					<!-- </template> -->
				</b-card>
				<!-- <div class="d-sm-none d-md-inline circle-box">
					<div class="circle-five">
						<svg
							preserveAspectRatio="none"
							data-bbox="0 0 200 200"
							xmlns="http://www.w3.org/2000/svg"
							width="162"
							height="162"
							viewBox="0 0 200 200"
							role="presentation"
							aria-hidden="true"
						>
							<g>
								<path
									d="M200 100c0 55.228-44.772 100-100 100S0 155.228 0 100 44.772 0 100 0s100 44.772 100 100z"
								></path>
							</g>
						</svg>
					</div>
					<div class="circle-six">
						<svg
							preserveAspectRatio="none"
							data-bbox="0 0 200 200"
							xmlns="http://www.w3.org/2000/svg"
							width="76"
							height="76"
							viewBox="0 0 200 200"
							role="presentation"
							aria-hidden="true"
						>
							<g>
								<path
									d="M200 100c0 55.228-44.772 100-100 100S0 155.228 0 100 44.772 0 100 0s100 44.772 100 100z"
								></path>
							</g>
						</svg>
					</div>
				</div> -->
			</b-col>

			<b-modal
				id="showClioConfirm"
				v-model="showClioConfirmModal"
				title="Are you sure ?"
			>	
				Sign in with CLIO will need to create new user in DIVII with the email id you are providing if the email id is not signed up in DIVII.
				Are you sure you want to proceed ?
 
				<b-alert variant="warning" class="mt-3" show>
					<p>Note : You will be prompted to enter the password on the next step for user creation in DIVII if not created already</p>
				</b-alert>
				<template #modal-footer="{}">
					<b-button size="sm" variant="primary" @click="clioIntegrationLogin()" data-testid="proceed-button"> Proceed </b-button>
					<b-button size="sm" @click="toggleClioConfirmModal()" data-testid="cancel-button"> Cancel and go back </b-button>
				</template>
			</b-modal>

			
			<b-modal
				id="showClioConfirmFromGoogle"
				v-model="showClioConfirmFromGoogle"
				title="Are you sure ?"
			>	
				Do you have a CLIO account which you want to integrate with CLIO ?
 
				<b-alert variant="warning" class="mt-3" show>
					<p>Note : You will be prompted to enter the password on the next step for user creation in DIVII if not created already</p>
				</b-alert>
				<template #modal-footer="{}">
					<b-button size="sm" variant="primary" @click="googleLoginWithClioLogin()" data-testid="proceed-button"> Proceed to login with CLIO</b-button>
					<b-button size="sm" @click="googleLogin()" data-testid="cancel-button"> No, I want to do a google login alone </b-button>
				</template>
			</b-modal>
		</b-row>

		<!-- <div class="circle-one">
			<svg
				preserveAspectRatio="none"
				data-bbox="0 0 200 200"
				xmlns="http://www.w3.org/2000/svg"
				width="200"
				height="200"
				viewBox="0 0 200 200"
				role="presentation"
				aria-hidden="true"
			>
				<g>
					<path
						d="M200 100c0 55.228-44.772 100-100 100S0 155.228 0 100 44.772 0 100 0s100 44.772 100 100z"
					></path>
				</g>
			</svg>
		</div>
		<div class="circle-two">
			<svg
				preserveAspectRatio="none"
				data-bbox="0 0 200 200"
				xmlns="http://www.w3.org/2000/svg"
				width="162"
				height="162"
				viewBox="0 0 200 200"
				role="presentation"
				aria-hidden="true"
			>
				<g>
					<path
						d="M200 100c0 55.228-44.772 100-100 100S0 155.228 0 100 44.772 0 100 0s100 44.772 100 100z"
					></path>
				</g>
			</svg>
		</div>
		<div class="circle-three">
			<svg
				preserveAspectRatio="none"
				data-bbox="0 0 200 200"
				xmlns="http://www.w3.org/2000/svg"
				width="162"
				height="162"
				viewBox="0 0 200 200"
				role="presentation"
				aria-hidden="true"
			>
				<g>
					<path
						d="M200 100c0 55.228-44.772 100-100 100S0 155.228 0 100 44.772 0 100 0s100 44.772 100 100z"
					></path>
				</g>
			</svg>
		</div>
		<div class="circle-four">
			<svg
				preserveAspectRatio="none"
				data-bbox="0 0 200 200"
				xmlns="http://www.w3.org/2000/svg"
				width="275"
				height="275"
				viewBox="0 0 200 200"
				role="presentation"
				aria-hidden="true"
			>
				<g>
					<path
						d="M200 100c0 55.228-44.772 100-100 100S0 155.228 0 100 44.772 0 100 0s100 44.772 100 100z"
					></path>
				</g>
			</svg>
		</div> -->
	</b-container>
</template>

<script>
/* global firebaseui */
import { auth, googleProvider } from '../firebase';
import EmailPasswordLogin from './EmailPasswordLogin.vue';
import { clioLogin } from '../clioIntegration';
import clientUserFormHelper from '@/mixins/clientUserFormHelper';

import 'firebaseui/dist/firebaseui.css';

export default {
	components: { EmailPasswordLogin },
	data() {
		return {
			user: null,
			emailLogin: false,
			openUserLoginAs:'',
			 
			showClioConfirmModal:false,
			showClioConfirmFromGoogle:false
		};
	},
	mixins: [clientUserFormHelper],
	mounted() {
		let ui = firebaseui.auth.AuthUI.getInstance();
		if (!ui) {
			ui = new firebaseui.auth.AuthUI(auth);
		}
		if(!this.isClientUserLogin)
		ui.start('#firebaseui-auth-container', {
			callbacks: {
				signInSuccessWithAuthResult: function(authResult, redirectUrl) {
				localStorage.setItem('loginProvider', "GOOGLE");
				return true;
				}
			},
			privacyPolicyUrl: 'https://www.divii.ca/privacy-policy',
			tosUrl: 'https://www.divii.ca/terms-of-service',
			signInSuccessUrl: '/', 
			callbacks: {
			signInSuccessWithAuthResult: function (authResult, redirectUrl) {
				return false;
			},
			},
			signInFlow: 'popup',
			signInOptions: [
			{
				provider: googleProvider,
			},
			],
		});

		auth.onAuthStateChanged(user => {
			if (user) {
				this.$router.push('/');
			} else {
				console.log('User is signed out');
			}
		});
		localStorage.removeItem('openUserLoginAs')
	},
	methods: {
		signup() {
			this.$store.dispatch('signup', {
				email: this.signupForm.email,
				password: this.signupForm.password,
				name: this.signupForm.name,
				title: this.signupForm.title,
			});
		},
		needsAccount() {
			this.$router.push({ name: 'trial' });
		},
		useEmail() {
			this.emailLogin = true;
		},
		clioIntegrationLogin(){
			this.showClioConfirmModal = false;
			clioLogin();
		},
		forgotPassword(){
			this.$router.push('/forgotpassword'); 
		},
		// showNormalAuthScreen(typeOfUserLogin){
		// 	this.openUserLoginAs = typeOfUserLogin;
		// 	localStorage.setItem('openUserLoginAs',typeOfUserLogin)
		// }

		toggleClioConfirmModal(){
			this.showClioConfirmModal = !this.showClioConfirmModal;
		},
		googleLogin(){
			this.showClioConfirmFromGoogle = true;
		},
		googleLoginWithClioLogin(){
			this.showClioConfirmFromGoogle = true;
		},
		googleLogin(){
			// const auth = getAuth();
			auth.signInWithPopup(provider)
				.then((result) => {
					/** @type {firebase.auth.OAuthCredential} */
					var credential = result.credential;

					// This gives you a Google Access Token. You can use it to access the Google API.
					var token = credential.accessToken;
					// The signed-in user info.
					var user = result.user;
					// IdP data available in result.additionalUserInfo.profile.
					// ...
				}).catch((error) => {
					// Handle Errors here.
					var errorCode = error.code;
					var errorMessage = error.message;
					// The email of the user's account used.
					var email = error.email;
					// The firebase.auth.AuthCredential type that was used.
					var credential = error.credential;
					// ...
				});
		},
	},
	computed: {
        isClientUserLogin(){
            return this.getClientOrPreviouslyLoggedId
        },
		// showSignInAsLawyerOption(){
		// 	return this.getPreviouslyLoggedClientUserAgreement && !this.openUserLoginAs ;
		// }
    }
};
</script>

<style>
.wrapper-row {
	height: calc(100vh - 170px);
}

.trial-wrapper {
	max-width: 500px;
	z-index: 2;
}

.circle-one {
	fill: #d4f0f0;
	opacity: 1;
	left: -40px;
	top: 100px;
	position: absolute;
}

.circle-two {
	fill: #e1efba;
	opacity: 0.6;
	left: 100px;
	top: 660px;
	position: absolute;
}

.circle-three {
	fill: #e1efba;
	opacity: 0.6;
	right: 80px;
	top: 150px;
	position: absolute;
}

.circle-four {
	fill: #d4f0f0;
	opacity: 0.6;
	right: 185px;
	top: 700px;
	position: absolute;
}

.circle-five {
	fill: #e1efba;
	opacity: 0.6;
	left: -80px;
	top: 50px;
	position: absolute;
}

.circle-six {
	fill: #d4f0f0;
	opacity: 1;
	left: -85px;
	top: 180px;
	position: absolute;
}

.forgot-password{
	font-size: 14px;
}
.clio-signIn{
	padding-top: 15px;
}
.form-control{
	cursor: pointer;
}
</style>
